import React from "react";
import styled from "styled-components";

const FooterCard = styled.div`
  background: #3a5a9a;
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: 3%;
  color: white;
  text-align: center;
  padding: 50px 0;
`;

export default function Footer() {
  return (
    <FooterCard>
      © 2020 Todos os Direitos Reservados Brapay Tecnologia LTDA. CNPJ
      05.794-273/0001-79
    </FooterCard>
  );
}
