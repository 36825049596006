import React, { Component, ComponentState } from "react";
import axios from "axios";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const WhiteCard = styled.div`
  justify-content: space-between;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  padding: 16px 24px;
  padding-top: 24px;
  flex-direction: column;
  display: flex;
  -webkit-box-pack: justify;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  width: 50%;
`;
const TitleCard = styled.p`
  text-align: center;
  padding: 0 15px 15px 15px;
  font-size: 2rem;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #f7941e;
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`;
const FormContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const Input = styled.input`
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d8dde1;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.12);
  padding: 8px 12px;
  width: calc(100% - 26px);
  font-size: 16px;
  line-height: 24px;
  transition: border-color 200ms ease-in-out;
  cursor: text;
  display: inline-block;
  text-align: right;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
`;
const SimulationResultContainer = styled.div`
  width: 100%;
  text-align: center;
`;
const BlockContainer = styled.div`
  width: 100%;
  display: block;
  position: relative;
  @media (max-width: 900px) {
    margin-bottom: 15px;
  }
`;
const Addornment = styled.span`
  color: #3a5a9a;
  line-height: 16px;
  width: 2ch;
  position: absolute;
  top: 22px;
  left: 1px;
  height: 16px;
  margin: 12px;
  pointer-events: none;
  font-size: 20px;
`;
const SelectInput = styled.select`
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d8dde1;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.12);
  padding: 8px 12px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  transition: border-color 200ms ease-in-out;
  cursor: text;
  display: inline-block;
  text-align: right;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
`;
const ButtonWhats = styled.button`
  padding: 15px;
  background: #377c16;
  box-shadow: 0px 2px 2px rgba(3, 169, 244, 0.2);
  border-radius: 3px;
  border: none;
  margin: 5px;
  color: white;
  cursor: pointer;
`;

interface SimulatorProps {}
interface SimulatorState {
  value: number;
  installment: number;
  valueFinal: number;
  valorParc: number;
  Parcelas: number;
}

export default class Simulator extends Component<
  SimulatorProps,
  SimulatorState
> {
  valorParc: any;
  constructor(props: number) {
    super(props);
    this.state = {
      value: 0,
      installment: 12,
      valueFinal: 0,
      valorParc: 0,
      Parcelas: 12,
    };

    this.handleChange = this.handleChange.bind(this);
    this.formHandler = this.formHandler.bind(this);
  }
  handleChange(event: any) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    } as ComponentState);
  }
  httpRequest() {
    let config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    let value = Number(this.state.value);
    this.setState({ value });
    axios
      .post(
        "https://brapayboletoapi.herokuapp.com/simulate",
        this.state,
        config
      )
      .then((response) => {
        // console.log(response);
        const valueFinal = response.data.simulatedObj.ValorVenda;
        const valorParc = response.data.simulatedObj.ValorParcelas;
        const Parcelas = response.data.simulatedObj.Parcelas;
        this.setState({ valueFinal, valorParc, Parcelas });
        // console.log("req", this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  formHandler = async (event: any) => {
    event.preventDefault();
    // setTimeout(() => console.log(this.state), 1000);
    setTimeout(() => this.httpRequest(), 1000);
  };
  formPreventDefault(e: any) {
    e.preventDefault();
  }
  ResultFinal() {
    let final = this.state.valueFinal;
    if (final != null) {
      return final.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }
    return final;
  }
  render() {
    const list = [
      { id: 1, name: "1 Parcela" },
      { id: 2, name: "2 Parcelas" },
      { id: 3, name: "3 Parcelas" },
      { id: 4, name: "4 Parcelas" },
      { id: 5, name: "5 Parcelas" },
      { id: 6, name: "6 Parcelas" },
      { id: 7, name: "7 Parcelas" },
      { id: 8, name: "8 Parcelas" },
      { id: 9, name: "9 Parcelas" },
      { id: 10, name: "10 Parcelas" },
      { id: 11, name: "11 Parcelas" },
      { id: 12, name: "12 Parcelas" },
    ];
    const { value, installment } = this.state;
    return (
      <Container>
        <WhiteCard>
          <TitleCard>Simulador</TitleCard>
          <FormContainer>
            <BlockContainer>
              <form
                onChange={this.formHandler}
                onSubmit={this.formPreventDefault}
              >
                <label
                  // tslint:disable-next-line: jsx-no-multiline-js
                  style={{
                    fontSize: "13px",
                    lineHeight: "20px",
                    color: "#666",
                    marginLeft: "5px",
                  }}
                >
                  Valor do boleto
                </label>
                <Addornment>R$</Addornment>
                <Input
                  value={value}
                  name="value"
                  onChange={this.handleChange}
                  type="number"
                  placeholder="0,00"
                  step="any"
                />
                <label
                  style={{
                    fontSize: "13px",
                    lineHeight: "20px",
                    color: "#666",
                    marginLeft: "5px",
                  }}
                >
                  Numero de parcelas
                </label>
                <SelectInput
                  value={installment}
                  onChange={this.handleChange}
                  name="installment"
                >
                  {list.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </SelectInput>
              </form>
            </BlockContainer>
            <SimulationResultContainer>
              <h4 style={{ color: "#3A5A9B", textTransform: "uppercase" }}>
                VOCÊ paga {this.state.Parcelas}x DE
              </h4>
              <h1 style={{ color: "#333333", textTransform: "uppercase" }}>
                R$ {this.state.valorParc}
              </h1>
              <h4 style={{ color: "#333333", textTransform: "uppercase" }}>
                TOTAL R$ {this.ResultFinal()}
              </h4>
              <a
                href={`https://api.whatsapp.com/send?phone=554541411400&text=Ola%20gostaria%20de%20parcelar%20meu%20boleto%20de%20${this.ResultFinal()}%20em%20${
                  this.state.Parcelas
                }%20vezes%20de%20${this.state.valorParc}!`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonWhats>Fale Conosco!</ButtonWhats>
              </a>
            </SimulationResultContainer>
          </FormContainer>
        </WhiteCard>
      </Container>
    );
  }
}
