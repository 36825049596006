import React from "react";
import { Title, DescriptionTitle } from "../Title";
import styled from "styled-components";
import Whats from "../../assets/whats.svg";
import Email from "../../assets/email.svg";

const WhatsLogo = styled.img`
  width: 10%;
  @media (max-width: 900px) {
    width: 25%;
  }
  @media (max-width: 768px) {
    width: 10%;
  }
`;
const EmailLogo = styled.img`
  width: 12%;
  @media (max-width: 900px) {
    width: 27%;
  }
  @media (max-width: 768px) {
    width: 12%;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const Content = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0 15%;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;
const WhatsNumber = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 10px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
  }
  @media (max-width: 300px) {
    display: none;
  }
`;
const WhiteCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 60%;
  text-align: center;
  margin: 2% 20%;
  @media (max-width: 768px) {
    margin: 2% 10%;
    width: 80%;
  }
`;
export default function Doubts() {
  return (
    <div>
      <Title>Dúvidas sobre o pagamento de contas?</Title>
      <FlexContainer>
        <Content>
          <FlexContainer>
            <WhatsLogo src={Whats} alt="logo whats" />
            <WhatsNumber>45 4141-1400</WhatsNumber>
          </FlexContainer>
          <FlexContainer>
            <EmailLogo src={Email} alt="logo email" />
            <WhatsNumber>contato@brapay.com</WhatsNumber>
          </FlexContainer>
        </Content>
      </FlexContainer>
      <WhiteCard>
        <DescriptionTitle>
          Rua Rui Barbosa, 978 - SL 01 - Centro - Foz do Iguaçu - PR
        </DescriptionTitle>
      </WhiteCard>
    </div>
  );
}
