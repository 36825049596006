import React from "react";
import Header from "../../components/Header";
import Simulator from "../../components/Simulator";
import {
  Title,
  DescriptionTitle,
  SubTitle,
  TitleContainer,
} from "../../components/Title";
import Cards from "../../components/Cards";
import Doubts from "../../components/Doubts";
import Footer from "../../components/Footer";

export default function Home() {
  return (
    <div>
      <Header />
      <br />
      <TitleContainer>
        <Title>Parcele seus boletos com a BraPay</Title>
        <DescriptionTitle>
          Com a Brapay você pode parcelar seus boletos em até 12x no cartão de
          crédito.
        </DescriptionTitle>
        <SubTitle>
          Parcele suas contas e tenha até 12 meses para pagar!
        </SubTitle>
      </TitleContainer>
      <br />
      <Simulator />
      <br />
      <Title>Como Funciona</Title>
      <Cards />
      <Doubts />
      <Footer />
    </div>
  );
}
