import React from "react";
import styled from "styled-components";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 20% 0 20%;
  @media (max-width: 1300px) {
    margin: 0 15% 0 15%;
  }
  @media (max-width: 1100px) {
    margin: 0 10% 0 10%;
  }
  @media (max-width: 850px) {
    margin: 0;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const WhiteCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  width: 286px;
  height: 200px;
  margin: 15px 20px 15px 20px;
  @media (max-width: 1100px) {
    height: 180px;
  }
  @media (max-width: 650px) {
    height: 150px;
  }
`;
const Title = styled.h2`
  color: #3a5a9a;
  padding: 15px;
  text-align: center;
  position: relative;
  @media (max-width: 1100px) {
    font-size: 1.2rem;
  }
  @media (max-width: 850px) {
    padding: 10px;
  }
`;
const Small = styled.p`
  color: #3a5a9a;
  text-align: center;
  font-weight: normal;
  padding: 5px 15px 15px 15px;
  font-size: 20px;
  line-height: 20px;
  @media (max-width: 1100px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export default function Cards() {
  return (
    <FlexContainer>
      <WhiteCard>
        <Title>Sem sufoco</Title>
        <Small>
          Regularize suas contas de imediato e ganhe mais tempo para pagar.
        </Small>
      </WhiteCard>
      <WhiteCard>
        <Title>Parcele</Title>
        <Small>
          Até 12x no cartão de crédito: você terá até 1 ano para quitar as
          parcelas que cabem no seu bolso.
        </Small>
      </WhiteCard>
      <WhiteCard>
        <Title>Segurança</Title>
        <Small>
          Faça tudo de forma online ou se preferir, nós vamos até você.
        </Small>
      </WhiteCard>
    </FlexContainer>
  );
}
