import React from "react";
import Logo from "../../assets/brapaylogo.png";
import styled from "styled-components";
import Whats from "../../assets/whats.svg";

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const HeaderContent = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;
const HeaderBar = styled.div`
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(54, 123, 245, 0.2);
  border-radius: 8px;
  width: 100%;
`;
const LogoBrapay = styled.img`
  width: 15%;
  @media (max-width: 1800px) {
    width: 20%;
  }
  @media (max-width: 1500px) {
    width: 25%;
  }
  @media (max-width: 1100px) {
    width: 30%;
  }
  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 500px) {
    width: 80%;
  }
`;
const WhatsLogo = styled.img`
  width: 20%;
  @media (max-width: 900px) {
    width: 25%;
  }
  @media (max-width: 400px) {
    width: 30%;
  }
  @media (max-width: 350px) {
    width: 20%;
  }
  @media (max-width: 300px) {
    width: 50%;
  }
`;
const WhatsNumber = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 10px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
  }
  @media (max-width: 300px) {
    display: none;
  }
`;
const LinkWhats = styled.a`
  text-decoration: none;
  color: #3f4c54;
`;

export default function Header() {
  return (
    <HeaderBar>
      <FlexContainer>
        <HeaderContent>
          <LogoBrapay src={Logo} alt="BraPay logo" />
        </HeaderContent>
        <HeaderContent>
          <LinkWhats
            href="https://api.whatsapp.com/send?phone=554541411400&text=Ola%20gostaria%20de%20parcelar%20meu%20boleto!"
            target="_blank"
          >
            <FlexContainer>
              <WhatsLogo src={Whats} alt="Whats log" />
              <WhatsNumber>45 4141-1400</WhatsNumber>
            </FlexContainer>
          </LinkWhats>
        </HeaderContent>
      </FlexContainer>
    </HeaderBar>
  );
}
