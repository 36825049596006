import React from "react";
import styled from "styled-components";

interface TitleProps {
  children?: any;
}

const H1 = styled.h1`
  color: #3a5a9a;
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
  padding: 20px;
  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media (max-width: 425px) {
    font-size: 1.4rem;
    padding: 10px;
  }
`;
const H2 = styled.h2`
  color: #3f4c54;
  font-size: 1.8rem;
  text-align: center;
  padding: 20px;
  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
  @media (max-width: 425px) {
    font-size: 1.1rem;
    padding: 10px;
  }
`;
const H4 = styled.h4`
  color: #3f4c54;
  text-align: center;
  font-weight: normal;
  padding: 15px;
  font-size: 1.5rem;
  @media (max-width: 1024px) {
    font-size: 1.3rem;
  }
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
  @media (max-width: 425px) {
    font-size: 1rem;
    padding: 8px;
  }
`;
const Container = styled.div`
  @media (min-width: 768px) {
    margin: 0 20% 0 20%;
  }
  @media (min-width: 1440px) {
    margin: 0 25% 0 25%;
  }
`;

export function Title(props: TitleProps) {
  return <H1>{props.children}</H1>;
}

export function SubTitle(props: TitleProps) {
  return <H2>{props.children}</H2>;
}

export function DescriptionTitle(props: TitleProps) {
  return <H4>{props.children}</H4>;
}

export function TitleContainer(props: TitleProps) {
  return <Container>{props.children}</Container>;
}

export default { Title, DescriptionTitle, SubTitle, TitleContainer };
